import React from "react";
import { ExternalLink } from "lucide-react";
import shiftsImage from "./shifts.jpg";
import productFinderImage from "./product-finder.jpg";
import dashboardImage from "./store-dashboard.jpg";
import serviceManualImage from "./service-manual.png";
import membershipImage from "./membership.jpg";
import localImage from "./local.png";
import quilttImage from "./quiltt.png";
import quiltt2Image from "./quiltt2.png";
import makethread2Image from "./makethread2.png";
import tarot1Image from "./tarot1.png";
import tarot2Image from "./tarot2.png";

const Portfolio = () => {
  const projects = [
    {
      title: "NHS Validated Relationships Service",
      role: "Tech Lead, NHS Digital",
      description:
        "National proxy access service enabling verified caregiving relationships (e.g., mother-child) to be surfaced across GP systems. Responsible for technical delivery and architecture of both the proxy service and its public-facing API, targeting interoperability at scale.",
      achievements: [
        "Led development of core backend services integrated into the NHS ecosystem",
        "Enabled surfacing of verified mother-child relationships across NHS services",
        "Designed event-driven architecture supporting 700k+ proxy role migrations",
        "Worked towards March 2026 goal of national adoption via GPIT supplier integrations",
      ],
      tech: "Terraform, Python, AWS Lambda, API Gateway, DynamoDB, FHIR, OAuth2, NHS Spine Integration",
      live: "https://digital.nhs.uk/developer/api-catalogue/validated-relationship-service",
      duration: "2025–Present",
      images: {
        // validatedRelationships1: validatedRelationships1Image,
        // validatedRelationships2: validatedRelationships2Image,
      },
    },
    {
      title: "Quiltt Retail Analytics Platform",
      role: "Chief Technology Officer",
      description:
        "Founded and built an AI-driven sales forecasting and analytics platform. Personally developed the machine learning capabilities and designed the high-availability infrastructure.",
      achievements: [
        "Built ML models using Python and XGBoost for accurate sales predictions",
        "Designed and implemented scalable Kubernetes platform on AWS",
        "Created automated CI/CD pipelines and testing infrastructure",
        "Led technical architecture and product strategy",
      ],
      tech: "Python, XGBoost, TypeScript, Deno, Next.js, AWS, Kubernetes",
      live: "https://quiltt-1i8d9m6sq-stead-tech.vercel.app/",
      duration: "2022-2023",
      images: {
        quiltt: quilttImage,
        quiltt2: quiltt2Image,
      },
    },
    {
      title: "Make Thread Social Fashion Marketplace",
      role: "Freelance Technical Lead / Fractional CTO",
      description:
        "Built a sustainable social fashion marketplace combining elements of Kickstarter and social networks. Created a complete platform enabling fashion creators to crowdfund and sell their sustainable designs.",
      achievements: [
        "Architected and built full-stack marketplace platform from scratch",
        "Implemented social networking features with user profiles and interactions",
        "Created crowdfunding capability with secure payment processing",
        "Built comprehensive admin interface for platform management",
        "Designed RESTful API supporting both marketplace and social features",
      ],
      tech: "React.js, Node.js, MongoDB, RESTful API, HTML5, CSS/SCSS",
      live: "https://www.makethread.com",
      duration: "2018-2019",
      image: makethread2Image,
    },
    {
      title: "Unique Tarot Co",
      role: "Founder & Creator",
      description:
        "Founded and built a luxury tarot brand creating one-of-a-kind, hand-finished tarot cards and artworks. Developed unique manufacturing processes and built a strong social media presence with a distinctive brand voice.",
      achievements: [
        "Created complete manufacturing pipeline for one-of-a-kind tarot cards",
        "Designed and implemented multi-format product lines (Singles, Frames, Custom Decks)",
        "Built engaged social media following with strong brand identity",
        "Developed luxury art production process with custom framing solutions",
        "Established direct-to-consumer sales channel through Instagram",
      ],
      tech: "E-commerce, Social Media Marketing, Custom Manufacturing, Digital Design, Luxury Product Development",
      social: "@uniquetarotco",
      live: "https://uniquetarotco.com",
      duration: "2020-Present",
      images: {
        tarot1: tarot1Image,
        tarot2: tarot2Image,
      },
      products: [
        {
          name: "Singles",
          description:
            "Made-to-order individual tarot cards, framed and hand-finished",
        },
        {
          name: "Frames",
          description:
            "Multi-card artworks available in 4, 9, 12, or 16 card configurations",
        },
        {
          name: "Custom Made Decks",
          description: "Luxury, bespoke full tarot decks",
        },
      ],
      brandHighlight:
        "For hot bitches and cool witches, our tarot is as unique as you are! Every piece is hand-finished and one-of-a-kind, combining mystical tradition with modern luxury.",
    },
    {
      title: "Target Group Mortgage Hub",
      role: "Technical Lead",
      description:
        "Led the development of an innovative mortgage origination platform. Built as a suite of microservices with multiple user portals, the platform transformed mortgage processing for major UK lenders.",
      achievements: [
        "Architected scalable platform processing thousands of mortgage applications",
        "Led development of 20+ microservices using event-driven architecture",
        "Built three user portals (broker, lender, and admin) in React/TypeScript",
        "Implemented real-time status tracking and document management",
        "Integrated with multiple third-party services for credit checks and valuations",
      ],
      tech: "React, TypeScript, Hasura, Apache Kafka, GraphQL, PostgreSQL, Java, Kubernetes, GitOps",
      live: "https://www.targetgroup.com/our-capabilities/mortgage-hub/",
      duration: "2022",
    },
    {
      title: "Gamstop National Self-Exclusion Platform",
      role: "Principal Engineer",
      description:
        "Led the transformation of the UK's national gambling self-exclusion service, building a platform that connects every gambling operator in the country and protects vulnerable users.",
      achievements: [
        "Architectural improvements to system handling 300+ million daily transactions across all UK gambling operators",
        "Consolidated 8 siloed platforms into unified, high-availability portal",
        "Established high-performance team culture under constant regulatory pressure",
        "Built comprehensive operator dashboard and reporting tools",
        "Delivered mission-critical platform with zero downtime requirements",
      ],
      tech: "TypeScript, React, Next.js, AWS (RDS, DMS, Lambda, API Gateway, ECS/Fargate, SQS, SES), Node.js, Java",
      live: "https://www.gamstop.co.uk",
      duration: "2024",
      impact: {
        transactions: "300M+ daily",
        operators: "All UK gambling operators",
        availability: "99.999% uptime",
        scale: "Nationwide service",
      },
      keyFeatures: [
        "Real-time operator integration",
        "High-availability infrastructure",
        "Comprehensive admin portal",
        "Automated verification systems",
        "Secure data handling",
        "Regulatory compliance",
      ],
    },
    {
      title: "NuGamma Restaurant Analytics",
      role: "Technical Lead",
      description:
        "Developed an AI-powered restaurant stock and staff prediction dashboard, achieving perfect customer satisfaction with a 10/10 NPS score.",
      achievements: [
        "Built predictive analytics platform for restaurant operations",
        "Achieved 10/10 NPS score from client",
        "Implemented staff scheduling optimization",
        "Created intuitive dashboard for operational insights",
      ],
      tech: "React, TypeScript, AI/ML, Predictive Analytics",
      duration: "2019-2022",
    },
    {
      title: "Local.co.uk Marketplace",
      role: "Technical Lead, Service Designer",
      description:
        "Led the development of a complete ecommerce platform for local artisans to sell handmade and bespoke products, delivered in just 14 weeks.",
      achievements: [
        "Built full marketplace platform with seller interface and API",
        "Delivered complete solution in aggressive 14-week timeline",
        "Implemented high-performance, scalable architecture",
        "Created comprehensive seller onboarding experience",
      ],
      tech: "Python, React.js, AWS",
      // video: "https://www.youtube.com/watch?v=htzRVgnYmb0",
      image: localImage,
      duration: "2018",
    },
    {
      title: "Co-op Shifts Platform",
      role: "Senior Software Engineer. Service Designer, User Researcher",
      description:
        "Built and scaled 'Shifts', a workforce management platform used by over 50,000 Co-op food store colleagues nationwide.",
      achievements: [
        "Scaled platform to 50,000+ active users",
        "Won 'Best Mobile Application' at Big Chip Awards 2018",
        "Implemented shift management and break entitlements",
        "Created team scheduling and preference management",
      ],
      tech: "Python, React.js, AWS, gRPC",
      live: "https://shifts.coop.co.uk",
      image: shiftsImage,
      duration: "2016-2018",
    },
    {
      title: "Co-op Digital Innovation",
      role: "Senior Software Engineer, Service Designer",
      description:
        "Delivered multiple innovative projects improving Co-op's digital presence and operational efficiency.",
      achievements: [
        "Built real-time store dashboard reducing paper-based tasks",
        "Created product finder reducing 20-minute process to seconds",
        "Developed digital service manual for consistent development practices",
        "Contributed to Co-op membership campaign",
      ],
      tech: "Ruby on Rails, React.js, Go, Real-time APIs",
      images: {
        dashboard: dashboardImage,
        productFinder: productFinderImage,
        serviceManual: serviceManualImage,
        membership: membershipImage,
      },
      links: {
        serviceManual: "https://digital-service-manual.herokuapp.com/",
        membership: "https://www.coop.co.uk/membership/join-us",
      },
      duration: "2016-2018",
    },
    {
      title: "Scottish Widows Pensions Platform",
      role: "Technical Lead",
      description:
        "Led an 11-person development team building a mission-critical pensions platform. Transformed the business unit through improved agile processes and data-driven development.",
      achievements: [
        "Implemented event-driven architecture handling billions in transactions",
        "Improved team velocity by 40% through process improvements",
        "Introduced modern development practices and monitoring",
        "Reduced deployment time from days to hours",
      ],
      tech: "React, Node.js, Kafka, GraphQL, Kubernetes, Java Microservices",
      duration: "2021-2022",
    },
    {
      title: "MPP Global Solutions",
      role: "Software Engineer",
      description:
        "Led critical infrastructure and feature development for a major CRM platform provider.",
      achievements: [
        "Led team of 5 developers and QAs",
        "Implemented multi-language support leading to multi-million pound deal",
        "Built custom cryptographic system for efficient voucher storage",
        "Deployed high-availability infrastructure across USA and Japan",
      ],
      tech: "Node.js, C#, ASP.NET, T-SQL, AWS",
      live: "https://www.mppglobal.com/",
      duration: "2015-2016",
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 via-white to-gray-50 pt-20">
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-indigo-900 via-purple-900 to-indigo-900 text-white py-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-5xl font-bold mb-6 bg-gradient-to-r from-white via-indigo-200 to-white bg-clip-text text-transparent">
            Technical Portfolio
          </h1>
          <p className="text-xl text-indigo-200 max-w-3xl">
            A decade of delivering innovative technical solutions across
            fintech, retail, and digital transformation. Each project
            demonstrates my ability to lead teams, architect scalable solutions,
            and drive business value.
          </p>
        </div>
      </div>

      {/* Projects Grid */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        <div className="grid grid-cols-1 gap-16">
          {projects.map((project, index) => (
            <div key={index} className="group relative">
              <div className="absolute inset-0 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-xl opacity-0 group-hover:opacity-100 transition-all duration-300 blur-xl"></div>
              <div className="relative bg-white/90 backdrop-blur-sm border border-gray-100/50 rounded-xl p-8 shadow-lg">
                {/* Project Header */}
                <div className="flex flex-col md:flex-row md:items-start md:justify-between mb-6">
                  <div>
                    <h2 className="text-3xl font-bold bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent mb-2">
                      {project.title}
                    </h2>
                    <div className="text-lg text-gray-600 mb-2">
                      {project.role}
                    </div>
                    <div className="text-sm text-indigo-600 font-medium">
                      {project.duration}
                    </div>
                  </div>
                  <div className="flex gap-4 mt-4 md:mt-0">
                    {project.live && (
                      <a
                        href={project.live}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-2 text-gray-600 hover:text-indigo-600 transition-colors"
                      >
                        <ExternalLink className="h-5 w-5" />
                        <span>Live Site</span>
                      </a>
                    )}
                  </div>
                </div>

                {/* Media Section */}
                {project.video && (
                  <div className="mb-6">
                    <iframe
                      width="100%"
                      height="400"
                      src={project.video}
                      frameBorder="0"
                      allow="autoplay; encrypted-media"
                      allowFullScreen
                      title={`${project.title} demo`}
                      className="rounded-lg shadow-lg"
                    />
                  </div>
                )}

                {project.image && (
                  <div className="mb-6">
                    <img
                      src={project.image}
                      alt={project.title}
                      className="w-full rounded-lg shadow-lg"
                    />
                  </div>
                )}

                {project.images && (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                    {Object.entries(project.images).map(([key, src]) => (
                      <img
                        key={key}
                        src={src}
                        alt={`${project.title} - ${key}`}
                        className="w-full rounded-lg shadow-lg"
                      />
                    ))}
                  </div>
                )}

                <p className="text-gray-700 text-lg mb-6">
                  {project.description}
                </p>

                <div className="mb-6">
                  <h3 className="text-lg font-semibold text-gray-900 mb-3">
                    Key Achievements
                  </h3>
                  <ul className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    {project.achievements.map((achievement, i) => (
                      <li
                        key={i}
                        className="flex items-start gap-2 text-gray-700"
                      >
                        <span className="text-indigo-500 mt-1">•</span>
                        {achievement}
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="pt-6 border-t border-gray-100">
                  <h3 className="text-lg font-semibold text-gray-900 mb-3">
                    Technologies Used
                  </h3>
                  <div className="flex flex-wrap gap-2">
                    {project.tech.split(", ").map((tech, i) => (
                      <span
                        key={i}
                        className="px-3 py-1 bg-indigo-50 text-indigo-600 rounded-full text-sm font-medium"
                      >
                        {tech}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
